// src/components/VersionChecker.tsx
import React, { useEffect, useRef } from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";

interface VersionData {
  version: string;
}

// const POLLING_INTERVAL = 15 * 60 * 1000; // Poll every 15 minutes
const POLLING_INTERVAL = 15 * 60 * 1000; // Poll every 15 minutes

const VersionChecker: React.FC = () => {
  // Ref to store the initial version fetched
  const currentVersionRef = useRef<string | null>(null);
  const serverOperation = useSelector((state: RootState) => state.session.serverOperation);

  const checkVersion = async (): Promise<void> => {
    try {

      // do not reload while confirming a demo
      if (!serverOperation) {
        // Fetch version.json. Using 'no-store' to bypass the browser cache.
        const response = await fetch(`${process.env.PUBLIC_URL}/version.json`, {
          cache: 'no-store',
        });

        if (!response.ok) {
          console.error('Failed to fetch version.json:', response.statusText);
          return;
        }

        const data: VersionData = await response.json();

        // Set the current version if not already set
        if (!currentVersionRef.current) {
          currentVersionRef.current = data.version;
          console.log(`Current version set to: ${data.version}`);
        } else if (currentVersionRef.current !== data.version) {
          // A new version is available; reload the page
          console.log(`New version detected: ${data.version}. Reloading...`);
          window.location.reload();
        }
      } else {
        console.log("%cSkipping version check while serverOperation ongoing", 'color: purple');
      }
    } catch (error) {
      console.error('Error fetching version.json:', error);
    }
  };

  useEffect(() => {
    // Run an initial version check when the component mounts
    checkVersion();

    // Set up the polling interval
    const intervalId = setInterval(checkVersion, POLLING_INTERVAL);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // This component does not render any visible content
  return null;
};

export default VersionChecker;
