import React, {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {FormattedMessage, useIntl} from "react-intl";
import { useSearchParams } from "react-router-dom";
import Footer from "./Footer";
import StarIcon from "@mui/icons-material/Star";
// @ts-ignore
import variables from "../../styles/_variables.scss";
import {openSnackBar} from "../global/globalSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../app/store";
import {I18nContext} from "../locale/LocaleWrapper";

interface ReviewProps {
  user: string;
  order: string;
  boutique: string;
  url: string;
}

const Review: React.FC = () => {
  const intl = useIntl();
  const { locale } = useContext(I18nContext);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Extract parameters from URL
  const uid = searchParams.get("uid");
  const oid = searchParams.get("oid");
  const initialRating = parseInt(searchParams.get("rating") || "0", 10);

  // State variables
  const [review, setReview] = useState<ReviewProps | null>(null);
  const [rating, setRating] = useState<number>(initialRating || 0);
  const [hoverRating, setHoverRating] = useState<number>(0); // Hover state
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [initialRatingValue, setInitialRatingValue] = useState<number>(initialRating);

  const invalidReview = () => {
    navigate(`/${locale}`);
    dispatch(openSnackBar({
      severity: 'error',
      message: intl.formatMessage({id: "review.invalid"}),
      noAutoClose: true}));
  }

  const updateRating = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/review/update`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uid, oid, rating }),
      });

      if (!response.ok) {
        setError(true);
        invalidReview();
      } else {
        const data = await response.json();
        setReview(data.review);
        setInitialRatingValue(data.review.rating);
        setLoading(false);
        setError(false);
      }

    } catch (err) {
      setError(true);
      invalidReview();
    }
  };

  // Fetch user, order, and submit the initial rating
  useEffect(() => {
    if (!uid || !oid) {
      invalidReview();
      return;
    }
    updateRating();
  }, [uid, oid, navigate]);

  // Handle rating change
  const handleRatingChange = (newRating: number) => {
    setRating(newRating);
  };

  const handleSubmit = () => {
    updateRating();
  };

  // Determine background color for stars
  const getStarBackgroundColor = (star: number): string => {
    const effectiveRating = hoverRating || rating; // Use hoverRating if active
    if (star <= effectiveRating) {
      if (effectiveRating <= 2) return "#ff6363"; // 1-2 stars
      if (effectiveRating === 3) return "#ffa54c"; // 3 stars
      if (effectiveRating >= 4) return "#01B67A"; // 4-5 stars
    }
    return "#e8e8e8"; // Non-activated stars
  };

  // Check if the Submit button should appear
  const hasChanges = rating !== initialRatingValue;

  if (loading) {
    return <h1>{intl.formatMessage({ id: "review.loading" })}</h1>;
  }

  if (error) {
    return null;
  }

  return (<>
    <div className="website">
      {review && <Box className="review">
	      <h1>{intl.formatMessage({id: "review.title"})}</h1>
	      <h2>{intl.formatMessage({id: "review.shop"}, {shop: review.boutique})}</h2>
	      <h2>{intl.formatMessage({id: "review.order"}, {order: review.order})}</h2>
	      <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "4px", margin: "20px 0",}}>
          {[1, 2, 3, 4, 5].map((star) => <div
            key={star}
            onClick={() => handleRatingChange(star)}
            onMouseEnter={() => setHoverRating(star)} // Set hover rating
            onMouseLeave={() => setHoverRating(0)} // Reset hover rating
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50px",
              height: "50px",
              cursor: "pointer",
              borderRadius: "0px",
              backgroundColor: getStarBackgroundColor(star), // Dynamic background color
              transition: "background-color 0.3s",
            }}
          >
            <StarIcon
              style={{
                fontSize: "30px",
                color: star <= (hoverRating || rating) ? "#FFFFFF" : "#FFFFFF", // Dynamic star color
                transition: "color 0.3s",
              }}
            />
          </div>)
          }
	      </div>

	      <h3>{intl.formatMessage({id: `review.user`}, {user: review.user})}</h3>
	      <p>
		      <a href={review.url} target="_blank" rel="noreferrer">
            {intl.formatMessage({id: "review.rdv"})}
		      </a>
	      </p>

        {hasChanges && <Box sx={{display: "flex", justifyContent: "center"}}>
		      <Button variant="contained" color="primary" onClick={handleSubmit}
		              style={{marginTop: "20px", marginBottom: "40px"}}>
            {intl.formatMessage({id: "review.submit"})}
		      </Button>
	      </Box>}
      </Box>}
      <Footer intl={intl}/>
    </div>
  </>);

};

export default Review;
