import {Navigate, useLocation} from "react-router-dom";
import {useContext, useEffect, useMemo, useState} from "react";
import {I18nContext, SUPPORTED_LOCALES} from "./LocaleWrapper";
import {useDispatch, useSelector} from "react-redux";

// Define paths for which locale enforcement should be skipped.
const LOCALE_EXCLUDED_PATHS = ["admin"];

const EnsureLocale: React.FC<{ children: React.ReactNode }> = ({ children}) => {
  const location = useLocation();
  const { locale, selectLanguage } = useContext(I18nContext);
  const [isLocaleReady, setIsLocaleReady] = useState(false);
  const pathSegments = useMemo(
    () => location.pathname.split("/").filter(Boolean),
    [location.pathname]
  );

  useEffect(() => {
    const handleLocaleUpdate = async () => {
      if (pathSegments.length > 0 && !LOCALE_EXCLUDED_PATHS.includes(pathSegments[0])) {
        const firstSegment = pathSegments[0];
        if (SUPPORTED_LOCALES.includes(firstSegment) && firstSegment !== locale && selectLanguage) {
          // console.log("EnsureLocale: updating locale to " + firstSegment);
          await selectLanguage(firstSegment);
        }
      }

      if (!isLocaleReady) setIsLocaleReady(true);
    };

    handleLocaleUpdate();
  }, [pathSegments, locale, selectLanguage]);

  // If there are any segments and the first one is excluded, do nothing.
  if (pathSegments.length > 0 && LOCALE_EXCLUDED_PATHS.includes(pathSegments[0])) {
    console.log("EnsureLocale: locale enforcement is skipped for path " + pathSegments[0]);
    return <>{children}</>;
  }

  // Don't render anything until locale is ready
  if (!isLocaleReady) {
    // console.log("EnsureLocale: locale not ready, returning null");
    return null;
  }

  // console.log("EnsureLocale: locale is ready, rendering children with locale=" + locale);

  // If there are no segments, redirect to "/<locale>"
  if (pathSegments.length === 0) {
    return <Navigate to={`/${locale}`} replace />;
  }

  const firstSegment = pathSegments[0];

  // If the first segment is not a supported locale, then redirect
  if (!SUPPORTED_LOCALES.includes(firstSegment)) {
    // Prepend the current locale to the entire pathname.
    return <Navigate to={`/${locale}${location.pathname}`} replace />;
  }

  // Otherwise, the URL already includes a valid locale.
  return <>{children}</>;
};

export default EnsureLocale;
