import './AppBar.scss';
import * as React from 'react';
import logo from '../../images/dagoba.png';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import StorefrontIcon from '@mui/icons-material/Storefront';
import HelpIcon from '@mui/icons-material/Help';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {To, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from "../../app/store";
import {
  reloadUser,
  ROLE_ADMIN,
  ROLE_BOUTIQUE,
  updateProfile,
  impersonatingUser, flagImpersonating
} from "../sessions/sessionSlice";
import {currentFilterKey, filterProducts, selectBrand} from "../catalog/catalogSlice";
import {useAppSelector} from "../../app/hooks";
import {useContext, useEffect} from "react";
import {I18nContext, SUPPORTED_LOCALES} from "../locale/LocaleWrapper";
import ReactCountryFlag from "react-country-flag"
import {FormattedMessage, useIntl} from "react-intl";
import {getAccessToken} from "../../app/sessionAPI";
import {filterProduction} from "../admin/production/productionSlice";
import {openDemoBoutique} from "../dashboard/boutiqueSlice";
import TShirtSvg from "../catalog/category/TShirtSvg";
import {showBackDrop} from "../global/globalSlice";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

const ResponsiveAppBar = () => {

  const intl = useIntl();
  const { locale } = useContext(I18nContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const isUserMenuOpen = Boolean(anchorElUser);

  const catalogVisible = useAppSelector((state: RootState) => state.global.catalogVisible);
  const helpVisible = useAppSelector((state: RootState) => state.global.helpVisible);

  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const loggedUser = currentUser && currentUser.id !== undefined;
  const canAskDemo = true;
  const userBoutique = loggedUser && currentUser.role !== undefined &&
      (currentUser.role >= ROLE_BOUTIQUE || impersonatingUser(currentUser))

  const userProduction = loggedUser && currentUser.production
  const userLogs = loggedUser && currentUser.superAdmin

  const userAdmin = loggedUser && currentUser.role === ROLE_ADMIN

  const dispatch = useDispatch<AppDispatch>();

  const currentFilter = useAppSelector(currentFilterKey);

  const accessToken = getAccessToken();


  useEffect(() => {
    if (accessToken && accessToken !== 'undefined' && !loggedUser) {
      // console.log("ResponsiveAppBar useEffect dispatching reloadUser because accessToken = ", accessToken)
      dispatch(reloadUser());
    }
  }, [dispatch, accessToken, currentUser]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  function handleNavigate(route: To, event: React.MouseEvent) {
    event?.preventDefault();
    navigate(route);
  }

  function handleLogout(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    event?.preventDefault();
    handleCloseUserMenu();
    dispatch(showBackDrop())
    navigate(`/${locale}/logout`);
  }

  function handleUpdateProfileAccount(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    event?.preventDefault();
    handleCloseUserMenu();
    navigate(`/${locale}/profile`);
  }

  const isEmpty = function(text: string): boolean {
    return text === null || text.match(/^ *$/) !== null;
  };

  function userDisplay() {
    if (loggedUser) {
      let first_last = currentUser.firstName + ' ' + currentUser.lastName;
      return (isEmpty(first_last) ? currentUser.email : first_last.trim());
    } else {
      // return <FormattedMessage id="global.account" />;
      return null;
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const search = event.target.value;

    if (search !== null && (search.length > 2 || search.length === 0)) {
      dispatch(filterProducts(search))
      if (userProduction) {
        dispatch(filterProduction(search))
      }
    }
  };

  // const handleClearSearch = (e:any) => {
  //   if (searchRef && searchRef.current) {
  //     const input: HTMLInputElement = searchRef.current as HTMLInputElement;
  //     input.value = ''
  //   }
  //
  //   dispatch(filterProducts(''))
  //   if (userProduction) {
  //     dispatch(filterProduction(''))
  //   }
  // }

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    // marginRight: theme.spacing(2),
    marginRight: 'clamp(0.3rem, 1vw, 2rem)',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      // marginLeft: theme.spacing(3),
      marginLeft: 'clamp(0.3rem, 1vw, 2rem)',
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1rem + ${theme.spacing(3)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '16ch',
      },
    },
  }));

  const handleUserMenuClose = () => {
    setAnchorElUser(null);
  };

  const clickBoutique = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (userBoutique) {
      handleNavigate(`/${locale}/dashboard`, event)
    } else {
      handleNavigate(`/${locale}/catalog`, event)
      dispatch(openDemoBoutique({open: true, loading: false}));
    }
  }

  const selectLocale = (event: SelectChangeEvent) => {
    const newLocale = event.target.value;

    // Update the URL:
    // Split the current pathname into segments.
    const segments = location.pathname.split("/");
    // If the first segment (after the initial empty string) is a supported locale,
    // replace it. Otherwise, prepend the new locale.
    if (segments[1] && SUPPORTED_LOCALES.includes(segments[1])) {
      segments[1] = newLocale;
    } else {
      segments.splice(1, 0, newLocale);
    }
    const newPath = segments.join("/");

    // Navigate to the new URL
    navigate(newPath, { replace: true });

    if (loggedUser) {
      dispatch(updateProfile({locale: event.target.value}));
    }
  };

  const menuId = 'primary-search-account-menu';

  return (
    <AppBar position="static" className={`app-bar ${process.env.NODE_ENV}`}>
      <Toolbar disableGutters>
        <img src={logo} onClick={() => navigate(`/${locale}`)}
             style={{width: 80, cursor: 'pointer',
               marginLeft: 'clamp(0px, 1vw, 0.5rem)', marginRight: 'clamp(0px, 1vw, 3rem)'}}/>
        <Box sx={{flexGrow: 1, display: 'flex'}}>
          <Button key='catalog-1' className='menu-button' onClick={(e) => handleNavigate(`/${locale}/catalog`, e)}>
            <Box className='tshirt'>{TShirtSvg('clamp(1rem, 2.4vw, 2.2rem)')}</Box>
            {!catalogVisible && <Box className='hide-xs' sx={{ml: 1}} >
              <FormattedMessage id="appbar.catalog"/>
            </Box>}
          </Button>
          {(canAskDemo || flagImpersonating || process.env.NODE_ENV === 'development' || userBoutique)
            && (!userProduction || userAdmin) &&
            <Button key='eshop-1' className='menu-button' onClick={clickBoutique}>
              <StorefrontIcon />
              <Box sx={{display: {xs: 'none', sm: 'flex'}, ml: 1}} >
                <FormattedMessage id={`appbar.${userBoutique ? 'dashboard' : 'eshop'}`} />
              </Box>
            </Button>
          }
          {!userProduction &&
            <Button key='helpcenter' className='menu-button'
                  onClick={(event) => handleNavigate(`/${locale}/help`, event)}>
              <HelpIcon />
              {!catalogVisible && <Box sx={{display: {xs: 'none', sm: 'flex'}, ml: 1}} >
                <FormattedMessage id="appbar.help"/>
              </Box>}
            </Button>
          }
          {userAdmin &&
            <Button key='products-1' className='menu-button'
                    onClick={(event) => handleNavigate("/admin/catalog", event)}
			              sx={{my: 2, color: 'white', display: 'block'}}>
				    <FormattedMessage id="appbar.products"/>
			    </Button>
          }
          {userProduction &&
            <Button key='production-1' className='menu-button'
                    onClick={(event) => handleNavigate("/admin/production", event)}
                    sx={{my: 2, color: 'white', display: 'block'}}>
				    <FormattedMessage id="appbar.production"/>
			    </Button>
          }
          {userLogs &&
            <Button key='logs-1' className='menu-button'
                    onClick={(event) => handleNavigate("/admin/logs", event)}
                    sx={{my: 2, color: 'white', display: 'block'}}>
				    Logs
			    </Button>
          }

          {/*{accessExpiryAt !== undefined && accessExpiryAt !== null && accessToken !== undefined && accessToken !== null &&*/}
          {/*    refreshToken !== undefined && refreshToken !== null &&*/}
          {/*  <Typography variant="body2" sx={{ my: 3, color: 'white', display: 'block' }}>*/}
          {/*    ({new Date(accessExpiryAt).toLocaleTimeString()}, {accessToken.slice(-10)}, {refreshToken.slice(-10)})*/}
          {/*  </Typography>*/}
          {/*}*/}
        </Box>
        {(catalogVisible || helpVisible || userProduction) &&
				    <Box sx={{flexGrow: 1}}>
					    <Search>
						    <SearchIconWrapper>
							    <SearchIcon/>
						    </SearchIconWrapper>
						    <StyledInputBase
								    // autoFocus
								    placeholder={`${intl.formatMessage({id: `global.search`})} ...`}
								    inputProps={{'aria-label': 'search'}}
								    onChange={(e) => {
                      handleSearch(e)
                    }}
								    defaultValue={currentFilter}
                  // inputRef={searchRef}
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //     <IconButton onClick={handleClearSearch} edge="end">
                  //       <CloseIcon fontSize='small' />
                  //     </IconButton>
                  //   </InputAdornment>
                  // }
						    />
					    </Search>
				    </Box>
        }
        {!catalogVisible && !userBoutique && !userProduction &&
          <Button color='success' variant='contained' key='start' size='small'
                  sx={{display: 'flex', fontSize: 'var(--fs-md)'}} onClick={clickBoutique}>
            <RocketLaunchIcon />
            <Box className='hide-xs' sx={{ml: 1, fontSize: 'var(--fs-normal)'}} >
              <FormattedMessage id="home.cta2" />
            </Box>
          </Button>
      }

        {/*<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />*/}
        <Box sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}, ml: 1, cursor: 'pointer'}} onClick={handleOpenUserMenu}>
          {userDisplay()}
        </Box>
        <Box sx={{flexGrow: 0, display: {xs: 'flex'}}}>
          <IconButton
            sx={{mr: '0px'}}
            size="medium"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
            color="inherit">
            <AccountCircle fontSize='large'/>
          </IconButton>
        </Box>
        <Menu
          sx={{mt: '35px'}}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {loggedUser && ([
            <MenuItem key='update' onClick={(event) => handleUpdateProfileAccount(event)}>
              <Typography textAlign="center"><FormattedMessage id="session.my-account"/></Typography>
            </MenuItem>,
            <MenuItem key='logout' onClick={(event) => handleLogout(event)}>
              <Typography textAlign="center"><FormattedMessage id="session.logout"/></Typography>
            </MenuItem>
          ]) || ([
            <MenuItem key='login' onClick={(event) => handleNavigate(`/${locale}/login`, event)}>
              <Typography textAlign="center"><FormattedMessage id="session.login"/></Typography>
            </MenuItem>,
            <MenuItem key='signup' onClick={(event) => handleNavigate(`/${locale}/signup`, event)}>
              <Typography textAlign="center"><FormattedMessage id="session.signup"/></Typography>
            </MenuItem>,
            // <MenuItem key='speedy' onClick={(event) => dispatch(openSpeedySignup())}>
            //   <Typography textAlign="center">Speedy signup</Typography>
            // </MenuItem>
          ])}
        </Menu>
        <Box sx={{mr: '10px', flexGrow: 0, display: {xs: 'flex'}}}>
          <Select className='select-lang' value={locale} disableUnderline variant='standard'
                  onChange={selectLocale}>
            <MenuItem value='en'><ReactCountryFlag countryCode="GB"/></MenuItem>
            <MenuItem value='fr'><ReactCountryFlag countryCode="FR"/></MenuItem>
          </Select>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ResponsiveAppBar;