import React, {useContext, useEffect, useState} from 'react';
import ColorTag from "./ColorTag";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GenderDisplayHash, IProduct, ProductPricesString, IVariantInfos} from "../../app/product";
import {openProduct} from "../productDetails/productDetailsSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {Card, CardActionArea, CardContent, CardMedia, Grid, Slider} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import { styled } from '@mui/material/styles';
import {ROLE_ADMIN, IUser} from "../sessions/sessionSlice";
import {getAccessToken} from "../../app/sessionAPI";
import BestSellerSvg from "./BestSeller";
import {IProductPropertyUpdate, updateProductPropertyRequest} from "../../app/productAPI";
import GenderVersion from "./GenderVersion";
import {useNavigate} from "react-router-dom";
import {I18nContext} from "../locale/LocaleWrapper";


function colorMatchInfo(variant:IVariantInfos) {
  return null;
  // return(
  //   <div style={{position: "absolute", top:0, left: '50%'}}>
  //     {variant.colorMatch} ({variant.color1}/{variant.color2})
  //   </div>
  // );
}

const ImgScaleDownSlider = styled(Slider)(({ theme }) => ({
  color: '#404040',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
  },
}));

export const ProductCard = (props: {
  product: IProduct,
  selected: boolean,
  user: IUser | undefined
}) => {

  const dispatch = useDispatch<AppDispatch>();
  const { locale } = useContext(I18nContext);
  const navigate = useNavigate();

  const {product, selected, user} = props
  const userAdmin = getAccessToken() !== undefined && user !== undefined && user.role === ROLE_ADMIN

  const displayedVariant = product.variants[0]
  const [variantImage, setVariantImage] = useState(displayedVariant ? displayedVariant.images[0] : '');

  useEffect(() => {
    if (displayedVariant && displayedVariant.images[0]) {
      setVariantImage(displayedVariant.images[0]);
    }
  }, [displayedVariant]);


  const [imgScaleDown, setImgScaleDown] = useState(product.imgScaleDown);

  const handleChangeScaleDown = (event: Event, newValue: number | number[]) => {
    event.stopPropagation();
    const newScaleDown = 100 - (newValue as number);
    setImgScaleDown(newScaleDown);

    const updateProperty = {
      productIds: [product.id],
      propertyName: 'img_scale_down',
      propertyValue: newScaleDown.toString()
    } as IProductPropertyUpdate;

    dispatch(updateProductPropertyRequest(updateProperty));
  };

  const [bestSeller, setBestSeller] = useState(product.bestSeller);

  const toggleBestSeller = (e:React.MouseEvent) => {

    if (!userAdmin) return;

    e.stopPropagation();

    setBestSeller(!bestSeller);

    const updateProperty = {
      productIds: [product.id],
      propertyName: 'best_seller',
      propertyValue: !product.bestSeller ? 'true' : 'false'
    } as IProductPropertyUpdate;

    dispatch(updateProductPropertyRequest(updateProperty));
  }


  const sizes = displayedVariant ? displayedVariant.sizes.join(", ") : "";

  const productBrand = (brand: string)=> <div className='brand'>{brand}</div>;

  const otherVersions = (product: IProduct)=> {
    if (product.otherVersions.length === 0) return '';

    return(
      <Box sx={{display:'flex', flexDirection: 'column', position: "absolute", top:5, right:5, width:30, zIndex:1}}>
        {product.otherVersions.map(connectedProduct =>
          <GenderVersion key={connectedProduct.id} catalogView={true} product={connectedProduct} />)}
      </Box>
    )
  }

  const VariantTag = (variant:IVariantInfos) => {
    return(
      <div key={`${variant.id}-${variant.hexColor1}-${variant.hexColor2}`}
           onMouseEnter={() => setVariantImage(variant.images[0])}
           onMouseLeave={() => setVariantImage(displayedVariant.images[0])}>
        <ColorTag color1={variant.hexColor1} color2={variant.hexColor2} active={false} size='medium' />
      </div>
    );
  }

  // console.log("Rendering ProductCard for product: ", product.title, " with selected: ", selected);

  if (!displayedVariant) {
    // console.log("%cNo variant for product: " + product.title, 'color: red');
    return null
  }

  const handleOpenProduct = () => {
    dispatch(openProduct(product));
    navigate(`/${locale}/catalog/${product.category}/${product.slug}`, { replace: true });
  }

  return (
    <Grid item xs={12} sm={6} md={4} key={product.id} className='product-box'>
      <Card className={`product ${selected ? 'selected' : ''}`}>

        <Box sx={{textAlign: 'center'}} onClick={handleOpenProduct}>
          {(bestSeller || userAdmin) &&
              <BestSellerSvg height='12%' left='5px' top='8%' activated={bestSeller} onClick={toggleBestSeller} />}
          <img src={variantImage} alt={product.title}
               style={{width:`${100 - imgScaleDown}%`,marginTop:`${imgScaleDown/2}%`,marginBottom:`${imgScaleDown/2}%`}} />
        </Box>

        {productBrand(product.brand)}
        {colorMatchInfo(displayedVariant)}
        {otherVersions(product)}
        {userAdmin && <ImgScaleDownSlider sx={{width:'90%', ml:'5%'}} defaultValue={100-imgScaleDown} valueLabelDisplay="auto" marks
                                           min={70} step={2} max={100} onChange={handleChangeScaleDown} />}

        <CardContent sx={{textAlign: 'center', padding: 'clamp(0.5rem, 1vw, 1rem)'}} onClick={handleOpenProduct}>
          <Typography variant="h5" component="div" sx={{fontSize: 'var(--fs-md)', fontWeight: 700}}>
            {product.title}
          </Typography>
          <Typography component="div" sx={{fontSize: 'var(--fs-normal)', fontWeight:400}}>
            {ProductPricesString(product)}
          </Typography>
          <Box sx={ {display:'flex', justifyContent: 'center'}}>
            {product.variants.filter((variant: IVariantInfos) => !variant.discontinued && variant.colorMatch > 0)
              .map((variant: IVariantInfos, index) => {
                if (index < 10) {
                  return VariantTag(variant);
                } else {
                  if (index < 13) {
                    return ".";
                  } else {
                    return '';
                  }
                }
              })}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}
