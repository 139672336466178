import React, {ChangeEvent, ReactElement, useContext, useEffect, useRef, useState} from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box, Stepper, StepLabel, Step, StepContent, Typography, Paper,
  DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Grid, Alert, AlertTitle, CircularProgress,
} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from '@mui/icons-material/Clear';
import {
  askDemoBoutique,
  cancelDemoBoutique,
  getBoutiques,
  IBoutiqueInfos,
  openDemoBoutique,
  updateBoutiques
} from "./boutiqueSlice";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import {SelectColor} from "../catalog/SelectColor";
import {ColorTypes} from "../../app/catalog";
import ColorTag from "../catalog/ColorTag";
import {useAppSelector} from "../../app/hooks";
import {primaryColor, secondaryColor} from "../catalog/catalogSlice";
import ImageUploading, { ImageListType } from "react-images-uploading";
import {ErrorsType, ImageType} from "react-images-uploading/dist/typings";
import {
  ISignupPayload,
  resetErrorState,
  signUpUser,
  updateUnconfirmedEmail
} from "../sessions/sessionSlice";
import {HexCode} from "../../app/color";
import {I18nContext} from "../locale/LocaleWrapper";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from '@mui/icons-material/Warning';
import {
  FOR_ALL_BG,
  FOR_DARK_BG,
  FOR_LIGHT_BG,
  getlogos,
  hasAutomaticLogoSelector,
  logosLoadedSelector,
  logosSelector, MAX_LOGO_FILE_SIZE
} from "../logos/logosSlice";
import {getAccessToken} from "../../app/sessionAPI";
import TimeProgressBar from "../utils/TimeProgressBar";

const NAME_MIN_LENGTH = 4;

export interface IRequestedDemoPayload {
  org_name: string;
  org_type: string;
  org_activity: string;
  logo_type: string;
  logo_file: string | undefined;
  logo_bg: number | null;
}

const organizationTypes = ['sports', 'school', 'company', 'other'];

const sportingActivities = [
  'basketball',
  'team',
  'judo', 'karate',
  'dance', 'gymnastics',
  'other'
];

export interface IUpdateDemoPayload {
  email: string
  boutique: IRequestedDemoPayload
}

const DEMO_LABELS = [
  'Création de la boutique ...',
  'Application des couleurs ...',
  'Création des collections ...',
  'Création des produits ...',
  'PRODUCT_LABELS',
  'Création des pages ...',
  'Création des menus ...',
  'Finalisation de la boutique ...',
];

const progressBarLabels = (boutique: IBoutiqueInfos) => {
  const productTitles = boutique.collections.flatMap((c) => c.products.map((p) => p.product.title))

  // return the labels from DEMO_LABELS and insert the product titles when demo label is PRODUCT_LABELS
  return DEMO_LABELS.flatMap((label) => label === 'PRODUCT_LABELS' ?
                productTitles.map((title) => `Ajout du produit ${title} ...`)
                          : label)
}

export const DemoBoutique = (props: {}) => {

  const intl = useIntl();
  const context = useContext(I18nContext);
  const dispatch = useDispatch<AppDispatch>();
  const currentBoutique = useSelector((state: RootState) => state.boutique.currentBoutique);
  const accessToken = getAccessToken();
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const saving = useSelector((state : RootState) => state.boutique.saving);
  const waiting = useSelector((state: RootState) => state.session.waiting);

  const color1 = useAppSelector(primaryColor);
  const color2 = useAppSelector(secondaryColor);

  const emailRef = useRef<HTMLInputElement>();
  const errorMessages = useSelector((state: RootState) => state.session.errorMessages);

  const [errors, setErrors] = useState<Array<string>>([])
  const [logoBackground, setLogoBackground] = useState<number|null>(null);

  const boutiquesLoaded = useAppSelector((state: RootState) => state.boutique.loaded);
  const logos = useSelector(logosSelector);
  const logosLoaded = useSelector(logosLoadedSelector);
  const hasAutomaticLogo = useSelector(hasAutomaticLogoSelector);

  ///////////////////////////////////////////////////////
  // Open/Close the demo boutique dialog
  ///////////////////////////////////////////////////////
  const open = useSelector((state : RootState) => state.boutique.demoBoutiqueOpen);
  const loading = useSelector((state : RootState) => state.boutique.demoBoutiqueLoading);
  const openingError = useSelector((state : RootState) => state.boutique.demoBoutiqueError);

  useEffect(() => {
    if (open) {
      setErrors([]);
    }
  }, [open]);

  useEffect(() => {
    if (openingError) {
      const errorLocalized = intl.formatMessage({id: `error.${openingError}`});
      setErrors([errorLocalized]);
    }
  }, [openingError]);

  const closeDemo = () => {
    setErrors([]);
    dispatch(openDemoBoutique({open: false, loading: false}));
  }

  ///////////////////////////////////////////////////////
  // Get boutique & logos if not already loaded
  ///////////////////////////////////////////////////////
  useEffect(() => {
    if (accessToken && accessToken !== 'undefined' && !logosLoaded) {
      dispatch(getlogos(''))
    }

    if (accessToken && accessToken !== 'undefined' && !boutiquesLoaded) {
      dispatch(getBoutiques());
    }

  }, [dispatch, accessToken]);


  interface IDemoConf {
    label: string
    status: string
  }
  const noDemoConf: IDemoConf = {label: "", status: ""};
  const [demoConf, setDemoConf] = useState<IDemoConf>(noDemoConf);

  ///////////////////////////////////////////////////////
  // fill the demo steps with the current boutique's data if any
  ///////////////////////////////////////////////////////
  useEffect(() => {
    if (currentBoutique && currentUser?.id) {
      setOrgaName(currentBoutique.name);
      setOrganizationType(currentBoutique.orgaType);
      setOrgaActivity(currentBoutique.orgaActivity);

      if (currentBoutique.buildingAt) {
        setDemoConf({
          label: intl.formatMessage({id: "boutique.demo-generate-building"},
            {email: currentUser.email, b: ((chunks : ReactElement[]) => {return(<b>{chunks}</b>)}) as any}),
          status: "success"
        })
      } else {
        if (currentBoutique.configApplied) {
          setDemoConf({
            label: intl.formatMessage({id: "boutique.demo-generate-done"},
              {email: currentUser.email, b: ((chunks : ReactElement[]) => {return(<b>{chunks}</b>)}) as any}),
            status: "success"
          })
        } else {
          const latestEmail = currentUser.unconfirmed_email || currentUser.email;
          setDemoConf({label: intl.formatMessage({id: "boutique.demo-generate-ok"},
              {email: latestEmail, b: ((chunks : ReactElement[]) => {return(<b>{chunks}</b>)}) as any}),
            status: "warning"
          })
        }
      }
    }

    if (currentUser && !currentUser.id) {
      handleReset();
    }

  }, [currentBoutique, currentUser]);

  ///////////////////////////////////////////////////////
  // fill the logos part of the demo with the current boutique's logo if any
  ///////////////////////////////////////////////////////
  useEffect(() => {
    if (logos && logos.length > 0) {
      if (hasAutomaticLogo) {
        setLogoType('automated');
      } else {
        setLogoType('uploaded');
      }
      setLogoBackground(logos[0].forDarkBg);
      setActiveStep(steps.length)
    }
  }, [logos, hasAutomaticLogo]);

  const [activeStep, setActiveStep] = useState(0);
  const [orgaName, setOrgaName] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setCurrentStep = (step: number) => {
    setActiveStep(step);
  }

  const orgaNameError = orgaName !== "" && orgaName.length < NAME_MIN_LENGTH;

  const [organizationType, setOrganizationType] = useState("");
  const handleOrganizationType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganizationType(event.target.value);
    setOrgaActivity("");
  }

  const [orgaActivity, setOrgaActivity] = useState("");
  const handleSportingActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrgaActivity(event.target.value);
  }

  const stepOrganization = () => {
    return (
      <Box sx={{ mt:3, mb: 2 }}>
        <FormControl>
          <Box sx={{mb:2}}>
            <TextField id="orga-name" label={intl.formatMessage({id: "boutique.demo-organization-name"})}
                       size="small" fullWidth defaultValue={orgaName}
                       error={orgaNameError}
                       helperText={orgaNameError ?
                         intl.formatMessage({id: "boutique.demo-orga-name-error"}) :
                         intl.formatMessage({id: "boutique.demo-organization-name-explain"})}
                       onBlur={(e) => setOrgaName(e.target.value)} />
          </Box>

          {orgaName !== "" && <Box sx={{mb: 2}}>
	          <FormLabel id="demo-type-orga-label">
		          <span className='radio-title'>{intl.formatMessage({id: "boutique.demo-type-orga"})}</span>
	          </FormLabel>
	          <Box sx={{fontSize: 'var(--fs-xs)', mb: 1}}>
		          {intl.formatMessage({id: "boutique.demo-type-orga-explain"})}
	          </Box>
	          <RadioGroup aria-labelledhover-colorby="demo-type-orga-label" name="radio-buttons-group"
	                      value={organizationType} onChange={handleOrganizationType}>
              {organizationTypes.map((type) =>
                  <FormControlLabel key={`demo-b-${type}`} value={type} control={<Radio />}
                    label={<span className={`radio-choice ${type === organizationType ? 'selected' : ''}`}>
                      {intl.formatMessage({id: `boutique.demo-type-orga-${type}`})}
                  </span>} />
              )}
	          </RadioGroup>
					</Box>}

          {organizationType === 'sports' && <Box sx={{mb: 2}}>
						<FormLabel id="demo-sporting-activity-label">
              <span className='radio-title'>{intl.formatMessage({id: "boutique.demo-sporting-activity"})}</span>
						</FormLabel>
	          <RadioGroup aria-labelledby="demo-sporting-activity-label" name="radio-buttons-group"
	                      value={orgaActivity} onChange={handleSportingActivity}>
              {sportingActivities.map((activity) =>
                <FormControlLabel key={`demo-b-${activity}`} value={activity} control={<Radio />}
                  label={<span className={`radio-choice ${orgaActivity === activity ? 'selected' : ''}`}>
                    {intl.formatMessage({id: `boutique.demo-sporting-activity-${activity}`})}
                </span>} />
              )}
	          </RadioGroup>
					</Box>}
        </FormControl>
      </Box>
    );
  }

  const stepColors = () => {
    return(<>
      <Box sx={{mb: 1}}>
        {intl.formatMessage({id: "boutique.demo-preferred-colours-explain"})}
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
        <SelectColor colorType={ColorTypes.Primary}  />
        <SelectColor colorType={ColorTypes.Secondary} />
      </Box>
    </>);
  }

  const [logoType, setLogoType] = useState("");
  const handleLogoType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogoType(event.target.value);
  }
  const [images, setImages] = useState([] as ImageType[]);

  const stepLogo = () => {
    return(<>
      <RadioGroup aria-labelledby="demo-type-orga-label" name="radio-buttons-group"
                  value={logoType} onChange={handleLogoType}>
        <FormControlLabel key='demo-logo-uploaded' className='logo-choice' value='uploaded' control={<Radio />}
                          label={intl.formatMessage({id: `boutique.demo-uploaded-logo`})} />
        {logoType === 'uploaded' && uploadLogoBox()}
        <FormControlLabel key='demo-logo-automated' className='logo-choice' value='automated' control={<Radio />}
                          label={intl.formatMessage({id: `boutique.demo-automated-logo`})} />
      </RadioGroup>

    </>);
  }

  const onImageChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    setImages(imageList);
    setLogoBackground(null)
  };

  const hasError = (imageErrors: ErrorsType) =>
    imageErrors !== null && (imageErrors.acceptType || imageErrors.maxFileSize)

  const selectLogoBg = (event: ChangeEvent<HTMLInputElement>) => {
    setLogoBackground(parseInt(event.target.value));
  }

  const uploadLogoBox = () => {
    return(<Box>
        <ImageUploading
          value={images}
          onChange={onImageChange}
          maxNumber={1}
          maxFileSize={MAX_LOGO_FILE_SIZE}
          acceptType={['png']}
        >
          {({imageList, onImageUpload, errors, onImageUpdate, onImageRemove, isDragging, dragProps}) =>
            <Box className='image-wrapper'>
              <div className='dropzone'>
                {(imageList.length == 0 || hasError(errors)) &&
                    <button className='load-logo' style={isDragging ? {backgroundColor: "#ddffdd"} : undefined}
                            onClick={onImageUpload} {...dragProps} disabled={saving}>
                      {hasError(errors) &&
                        <div className='errors'>
                          {errors?.acceptType && <span><FormattedMessage id="logos.type-not-allowed-demo" /></span>}
                          {errors?.maxFileSize && <span><FormattedMessage id="logos.max-file-size" values={{size: MAX_LOGO_FILE_SIZE / 1000000}} /></span>}
                        </div>
                        || <FormattedMessage id="boutique.demo-logo-click-or-drop" />
                      }
                    </button>
                }
                { !hasError(errors) &&  <>
                  {imageList.map((image, index) => (
                    <div className='image-item'>
                      {image.dataURL && <>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                          <Box className='logo-preview light'>
                            <Box>
                              <img src={image.dataURL}/>
                              {logoBackground === FOR_DARK_BG && <>
                                <Box sx={{position: 'absolute', left: '50%', top: '50%',
                                          marginTop: '-128px', marginLeft: '-128px'}}>
                                  <ClearIcon sx={{fontSize: 256, color: 'red', opacity: 0.4}} />
                                </Box>
                                <Box sx={{position: 'absolute', left: 0, bottom: '2%',
                                          textAlign: 'center', width:'100%', color: 'red', opacity: 0.4}}>
	                                <FormattedMessage id="logos.no-bg-white" />
                                </Box>
                              </>}
                            </Box>
	                          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              {(logoBackground === FOR_LIGHT_BG || logoBackground === FOR_ALL_BG) &&
                                  <CheckCircleIcon sx={{fontSize: 32, color: 'green', mr: 1}} />
                              }
                            </Box>
                          </Box>
	                        <Box className='logo-preview dark'>
		                        <Box>
                              <img src={image.dataURL}/>
                              {logoBackground === FOR_LIGHT_BG && <>
	                              <Box sx={{position: 'absolute', left: '50%', top: '50%',
                                  marginTop: '-128px', marginLeft: '-128px'}}>
		                              <ClearIcon sx={{fontSize: 256, color: 'red', opacity: 0.4}} />
	                              </Box>
	                              <Box sx={{position: 'absolute', left: 0, bottom: '2%',
                                  textAlign: 'center', width:'100%', color: '#ff8A8A'}}>
		                              <FormattedMessage id="logos.no-bg-dark" />
	                              </Box>
                              </>}
                            </Box>
		                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              {(logoBackground === FOR_DARK_BG || logoBackground === FOR_ALL_BG) &&
					                        <CheckCircleIcon sx={{fontSize: 32, color: 'green', mr: 1}} />
                              }
		                        </Box>
                          </Box>
                        </Box>

                        <Box>
	                        <RadioGroup sx={{justifyContent: 'space-between'}} row onChange={selectLogoBg} >
		                        <FormControlLabel value={FOR_LIGHT_BG} control={<Radio />} label={intl.formatMessage({id: "logos.select-bg-white"})} />
		                        <FormControlLabel value={FOR_DARK_BG} control={<Radio />} label={intl.formatMessage({id: "logos.select-bg-dark"})} />
		                        <FormControlLabel value={FOR_ALL_BG} control={<Radio />} label={intl.formatMessage({id: "logos.select-bg-all"})} />
                          </RadioGroup>
                          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',
                            fontSize: 'var(--fs-md)',
                            color: logoBackground === null ? '#1976d2' : 'darkgrey' }}>
                            {logoBackground === null && <WarningIcon sx={{fontSize: 32, color: '#1976d2', mr: 1}} />}
                            {intl.formatMessage({id: "boutique.demo-select-bg"})}
                          </Box>
                        </Box>

                        <div className="image-change">
                          <IconButton onClick={() => onImageUpdate(index)} disabled={saving} sx={{size:'small'}}>
                            <ImageSearchIcon />
                          </IconButton>
                        </div>
                        <div className="image-delete">
                          <IconButton onClick={() => onImageRemove(index)} disabled={saving} sx={{size: 'small'}}>
                            <DeleteIcon/>
                          </IconButton>
                        </div>
                      </>
                      }
                    </div>
                  ))}
                </>}
              </div>
            </Box>
          }
        </ImageUploading>
      </Box>);
  }

  const steps = [
    {
      label: intl.formatMessage({id: "boutique.demo-organization"}),
    },
    {
      label: intl.formatMessage({id: "boutique.demo-preferred-colours"}),
    },
    {
      label: intl.formatMessage({id: "boutique.demo-logo"}),
    },
  ];

  const labelComplement = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        if (orgaName === "") return "";
        return (<>
          <Box sx={{display: 'flex', alignItems: 'center', ml: 1, mr: 1}}>:</Box>
          <strong>{orgaName}</strong>
        </>);

      case 1:
        return(<>
          <Box sx={{display: 'flex', alignItems: 'center', ml: 1, mr: 1}}>:</Box>
          <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
            <ColorTag key='demo-c1' active={false} size='large' color1={HexCode(color1)} color2={undefined} />
            {color2 !== 'none' &&
								<ColorTag key='demo-c2' active={false} size='large' color1={HexCode(color2)} color2={undefined} />
            }
          </Box>
        </>);

      case 2:
        return(<>
          { logoType === 'uploaded' && ((images.length > 0 && images[0].dataURL) || currentBoutique?.logoUrl) && <>
						<Box sx={{display: 'flex', alignItems: 'center', ml: 1, mr: 1}}>:</Box>
						<Box className='logo-thumbnail' sx={{backgroundColor: logoBackground === FOR_DARK_BG ? '#101010' : 'white'}}>
							<img src={images[0]?.dataURL || currentBoutique?.logoUrl} />
						</Box>
					</>}
          { logoType === 'automated' && <>
            <Box sx={{display: 'flex', alignItems: 'center', ml: 1, mr: 1}}>:</Box>
	          <strong><FormattedMessage id="boutique.demo-automated-logo-chosen" /></strong>
          </>}
        </>);

      default:
        return "";
    }
  }

  const stepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return stepOrganization();

      case 1:
        return stepColors();

      case 2:
        return stepLogo();

      default:
        return 'Unknown stepIndex';
    }
  }

  const stepCompleted = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return orgaName !== "" && !orgaNameError && organizationType !== "" && (organizationType !== 'sports' || orgaActivity !== "");

      case 1:
        return true;

      case 2:
        return (logoType === 'uploaded' && ((images[0] && images[0].dataURL && logoBackground !== null) || currentBoutique?.logoUrl))
          || logoType === 'automated';

      default:
        return false;
    }
  }

  const allStepsCompleted = stepCompleted(0) && stepCompleted(1) && stepCompleted(2);


  const isStepFailed = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        if (activeStep > 0) {
          if (orgaName === "") {
            return intl.formatMessage({id: "boutique.demo-orga-name-required"});
          } else if (orgaName.length < NAME_MIN_LENGTH) {
            return intl.formatMessage({id: "boutique.demo-orga-name-too-short"}, {min: NAME_MIN_LENGTH});
          } else if (organizationType === "") {
            return intl.formatMessage({id: "boutique.demo-type-orga-required"});
          } else if (organizationType === 'sports' && orgaActivity === "") {
            return intl.formatMessage({id: "boutique.demo-sporting-activity-required"});
          } else {
            return null;
          }
        } else return null;

      case 1:
        return null;

      case 2:
        return activeStep > 2 &&
          ((logoType === 'uploaded' && ((!images[0] || !images[0].dataURL) && !currentBoutique?.logoUrl)) ||
            logoType === '');

      default:
        return false;
    }
  }

  const handleReset = () => {
    setErrors([]);
    setOrgaName("");
    setOrganizationType("");
    setOrgaActivity("");
    setLogoType("");
    setImages([]);
    setActiveStep(0);
    setDemoConf(noDemoConf);
    setLogoBackground(null);
  };

  ///////////////////////////////////////////////////////////////////////////////
  // Handle the demo request submission
  ///////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    emailRef?.current?.focus();
    if (errorMessages.length > 0) {
      setErrors(errorMessages.map((message: string) => intl.formatMessage({id: 'error.' + message})));
      dispatch(resetErrorState());
    }
  }, [errorMessages])

  const disabled = saving || waiting || (logoBackground === null && logoType === 'uploaded');

  async function handleSubmit() {
    setErrors([]);
    if (emailRef?.current === undefined || emailRef.current.value === "") {
      return setErrors([intl.formatMessage({id: "session.type-email"})])
    }

    // case where the user is already logged in (probably because a first demo was requested)
    if (currentUser && currentUser.id) {
      const payload: IUpdateDemoPayload = {
        email: emailRef.current.value,
        boutique: {
          org_name: orgaName,
          org_type: organizationType,
          org_activity: orgaActivity,
          logo_type: logoType,
          logo_file: logoType === 'uploaded' ? images[0].dataURL : '',
          logo_bg: logoBackground
        }
      }

      const response = await dispatch(askDemoBoutique(payload)) as any;

      if (response.type === 'boutique/askDemoBoutique/fulfilled') {
        // console.log('Boutique demo demandée, update email of user if necessary');
        if (currentUser.email !== payload.email) {
          dispatch(updateUnconfirmedEmail(payload.email));
        }
        setDemoConf({
          label: intl.formatMessage({id: "boutique.demo-generate-ok"},
            {email: payload.email, b: ((chunks : ReactElement[]) => {return(<b>{chunks}</b>)}) as any}),
          status: "warning"
        })

      } else {
        setDemoConf(noDemoConf);
        switch(response.payload.error) {
          case 'rejected':
            setErrors(['error.rejected']);
            break;
          default:
            setErrors([intl.formatMessage({id: `error.${response.payload.error}`})]);
        }
      }

    } else {

      // case where the user is unknown, we need to request the demo while signing up
      const payload: ISignupPayload = {
        email: emailRef.current.value,
        firstName: '',
        lastName: '',
        password: '',
        locale: context?.locale,
        color1: color1,
        color2: color2,
        boutique: {
          org_name: orgaName,
          org_type: organizationType,
          org_activity: orgaActivity,
          logo_type: logoType,
          logo_file: logoType === 'uploaded' ? images[0].dataURL : '',
          logo_bg: logoBackground
        }
      }

      const response = await dispatch(signUpUser(payload)) as any;

      if (response.type === 'session/signUpUser/fulfilled') {
        if (response.payload.requestedBoutique) {
          dispatch(updateBoutiques([response.payload.requestedBoutique]));
          setDemoConf({
            label: intl.formatMessage({id: "boutique.demo-generate-ok"},
              {email: payload.email, b: ((chunks : ReactElement[]) => {return(<b>{chunks}</b>)}) as any}),
            status: "warning"
          })
        } else {
          setDemoConf(noDemoConf);
          setErrors(['error.rejected']);
        }
      }
    }

  }

  async function handleCancel() {

    const response = await dispatch(cancelDemoBoutique()) as any;

    if (response.type === 'boutique/cancelDemoBoutique/fulfilled') {
      setErrors([]);
      setActiveStep(0);
      setDemoConf(noDemoConf);
    } else {
      setDemoConf(noDemoConf);
      setErrors(['error.rejected']);
    }
  }

  const errorsCard = (errorList: string[]) => <>
    {errorList.length > 0 &&
        <Box className="callout error">
    <Box className='title'>
      <ErrorIcon fontSize={'small'} sx={{color: 'darkred', mr: 1}}/>
      <Box>{intl.formatMessage({id: "global.error"})}</Box>
    </Box>
    <Box sx={{fontSize: 'var(--fs-s)'}}>
      {errorList.map((error, index) => <Box key={`alert-${index}`}>
        {error === 'error.rejected' ? intl.formatMessage({id: "boutique.demo-generate-reject"}) :
          error === 'error.token' ? intl.formatMessage({id: "boutique.demo-generate-token"}) :
            error}
      </Box>)}
    </Box>
  </Box>}</>;

  // console.log("%cDemoBoutique rendering with currentBoutique duration " + currentBoutique?.duration, 'color: purple', currentBoutique);

  return(
    <Dialog fullWidth={true} maxWidth={'md'} open={open} className='demo-boutique'>
      <DialogTitle>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box className='demo-title'><FormattedMessage id="boutique.see-demo" /></Box>
          <Box className='demo-subtitle'><FormattedMessage id="boutique.see-demo-explanation" /></Box>
        </Box>
        <IconButton aria-label="close" onClick={closeDemo} sx={{position: 'absolute', right: 8, top: 8, color: 'inherit'}}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{
          // minWidth:330,
          fontSize: 'var(--fs-md)' }}>
          {loading && <Box className='demo-loading'>
	          <FormattedMessage id="boutique.demo-searching"/><br/><br/>
	          <CircularProgress color="inherit" size={60}/>
          </Box> || <>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => {

              const labelProps: {
                optional?: React.ReactNode;
                error?: boolean;
              } = {};

              const failMessage = isStepFailed(index);
              if (failMessage) {
                labelProps.optional = failMessage;
                labelProps.error = true;
              } else {
                if (index === 0 && orgaName !== "" && organizationType !== "") {
                  if (organizationType === 'sports' && orgaActivity !== "") {
                    labelProps.optional = intl.formatMessage({id: `boutique.demo-type-orga-sports`}) + ' - ' + intl.formatMessage({id: `boutique.demo-sporting-activity-${orgaActivity}`});
                  } else {
                    labelProps.optional = intl.formatMessage({id: `boutique.demo-type-orga-${organizationType}`});
                  }
                }
              }

              return(
                <Step key={step.label} >
                  <StepLabel {...labelProps} sx={{fontSize: 'var(--fs-s)'}}>
                    <Box sx={{display: 'flex', alignContent: 'center' }} className='demo-step-title'
                         onClick={() => setCurrentStep(index)}>
                      <Box sx={{display: 'flex', alignItems: 'center' }}>
                      {step.label}</Box>{labelComplement(index)}
                    </Box>
                  </StepLabel>
                  <StepContent>
                    {stepContent(index)}
                    <Box sx={{mt:3}}>
                      {stepCompleted(index) && <>
					              <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
						              <FormattedMessage id={`global.${index === steps.length - 1 ? 'finish' : 'continue'}`} />
					              </Button>
				              </>}
                      {index > 0 && <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        <FormattedMessage id="global.back" />
                      </Button>}
                    </Box>
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length && <>

            {demoConf.label !== "" && <>
              <Box className={`callout ${demoConf.status}`}>
                <Box className={`title ${demoConf.status === 'success' ? 'center' : ''}`}>
                  {demoConf.status === "success" && <CheckCircleIcon sx={{fontSize: 28, color: 'green', mr: 1}} />}
                  {demoConf.status === "warning" && <WarningIcon sx={{fontSize: 24, color: 'orange', mr: 1}} />}
                  <Box sx={{fontSize: 'var(--fs-normal)'}}>{demoConf.label}</Box>
                </Box>
              </Box>
            </> || <>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                <FormattedMessage id="boutique.demo-reset" />
              </Button>
              {allStepsCompleted && <Paper square elevation={0} sx={{ p: 1 }}>

                {(errors.length === 0 || (errors[0] !== 'error.rejected' && errors[0] !== 'error.token')) &&
                  <Box className="callout success">
                    <Box className='title'>
                      <CheckCircleIcon sx={{fontSize: 28, color: 'green', mr: 1}} />
                      <Box sx={{fontSize: 'var(--fs-md)'}}><FormattedMessage id="boutique.demo-ready" /></Box>
                    </Box>
                  </Box>}

                <Box sx={{display: 'flex', flexDirection: 'column', mt: 2}}>
                  <Typography sx={{fontSize: 'var(--fs-ms)', color: '#808080'}} >
                    <FormattedMessage id="boutique.demo-email-address" />
                  </Typography>


                  <TextField id="demo-email" autoFocus margin="dense" type="email" fullWidth variant="standard"
                             inputRef={emailRef} label={intl.formatMessage({id: "session.email"})}
                             defaultValue={currentUser?.email || ""}
                             helperText={intl.formatMessage({id: "session.email-privacy"})} />
                </Box>

              </Paper>}
              </>
            }
          </>}
          {errorsCard(errors)}
          </>}
        </Box>
      </DialogContent>
      <DialogActions>
        {!loading && activeStep === steps.length && allStepsCompleted &&
          <Box sx={{display: 'flex', justifyContent: 'center', mb: 1, alignItems: 'center', width: '100%'}}>

            {!currentBoutique &&
              <Button variant="contained" disabled={disabled} color="primary" size={'large'} onClick={handleSubmit}>
                {intl.formatMessage({id: "boutique.demo-generate"})}
                {disabled && <CircularProgress size={20} sx={{color: 'white', marginLeft: '10px'}}/>}
              </Button>
            }

            {currentBoutique && !currentBoutique.buildingAt && !currentBoutique.configApplied &&
              <Button variant="contained" disabled={disabled} color='warning' size={'large'} onClick={handleCancel}>
                {intl.formatMessage({id: "boutique.demo-cancel"})}
                {disabled && <CircularProgress size={20} sx={{color: 'white', marginLeft: '10px'}}/>}
              </Button>
            }

            {currentBoutique && currentBoutique.buildingAt &&
              <Box sx={{ flexGrow: 1, p: 1, ml: 1, mr: 1 }}>
                <TimeProgressBar startingTime={currentBoutique.buildingAt} duration={currentBoutique.duration}
                                 labels={progressBarLabels(currentBoutique)} completeProgress={false}
                                 startingLabel={intl.formatMessage({id: "boutique.demo-generate-wait"})}
                                 height={15} backgroundColor={'hsl(144, 72%, 95%)'}
                                 barColor={'hsl(144, 72%, 21%)'} borderColor={'hsl(144, 72%, 87%)'}
                />
              </Box>
            }

            {currentBoutique && currentBoutique.id && !currentBoutique.buildingAt && currentBoutique.configApplied &&
              <Box sx={{ flexGrow: 1, p: 1, ml: 1, mr: 1 }}>

	              <Box className='boutique-link'
	                   onClick={() => window.open(`https://${currentBoutique.subdomain}.${process.env.REACT_APP_BOUTIQUES_DOMAIN}`, '_blank')}>
		              https://{currentBoutique.subdomain}.{process.env.REACT_APP_BOUTIQUES_DOMAIN}
	              </Box>
              </Box>
            }

            {disabled && (
              <CircularProgress
                size={24}
                sx={{
                  color: 'white',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}

          </Box>
        }
      </DialogActions>
    </Dialog>
  )
}
